import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { SharedCheckrecordModule } from '../shared-checkrecord/shared-checkrecord.module';
import { SharedMaterialModule } from '../shared-material/shared-material.module';
import { SharedRiskrecordModule } from '../shared-riskrecord/shared-riskrecord.module';
import { SharedPersonalComponent } from './shared-personal.component';
import { SharedRelateCompanyModule } from '../shared-relate-company/shared-relate-company.module';
import { SharedUpdaterecordModule } from '../shared-updaterecord/shared-updaterecord.module';


@NgModule({
    declarations: [SharedPersonalComponent],
    imports: [
        SharedModule,
        SharedCheckrecordModule,
        SharedMaterialModule,
        SharedRelateCompanyModule,
        SharedRiskrecordModule,
        SharedUpdaterecordModule
    ],
    exports: [SharedPersonalComponent],
})
export class SharedPersonalModule { }